import { Mutable } from "../index";
import { BrowserCompatibility } from "./browserCompatibility";
import { BrowserHelper } from "./browserHelper";
import { Camera } from "./camera";
import { UnsupportedBrowserError } from "./unsupportedBrowserError";

/**
 * A helper object to interact with cameras.
 */
export namespace CameraAccess {
  /**
   * @hidden
   *
   * Handle localized camera labels. Supported languages:
   * English, German, French, Spanish (spain), Portuguese (brasil), Portuguese (portugal), Italian,
   * Chinese (simplified), Chinese (traditional), Japanese, Russian, Turkish, Dutch, Arabic, Thai, Swedish,
   * Danish, Vietnamese, Norwegian, Polish, Finnish, Indonesian, Hebrew, Greek, Romanian, Hungarian, Czech,
   * Catalan, Slovak, Ukraininan, Croatian, Malay, Hindi.
   */
  const backCameraKeywords: string[] = [
    "rear",
    "back",
    "rück",
    "arrière",
    "trasera",
    "trás",
    "traseira",
    "posteriore",
    "后面",
    "後面",
    "背面",
    "后置", // alternative
    "後置", // alternative
    "背置", // alternative
    "задней",
    "الخلفية",
    "후",
    "arka",
    "achterzijde",
    "หลัง",
    "baksidan",
    "bagside",
    "sau",
    "bak",
    "tylny",
    "takakamera",
    "belakang",
    "אחורית",
    "πίσω",
    "spate",
    "hátsó",
    "zadní",
    "darrere",
    "zadná",
    "задня",
    "stražnja",
    "belakang",
    "बैक",
  ];

  /**
   * @hidden
   */
  const cameraObjects: Map<string, Camera> = new Map<string, Camera>();

  /**
   * @hidden
   */
  const inaccessibleCameras: Set<string> = new Set<string>();

  /**
   * @hidden
   */
  let getCamerasPromise: Promise<Camera[]> | undefined;

  /**
   * @hidden
   *
   * @param label The camera label.
   * @returns Whether the label mentions the camera being a back-facing one.
   */
  function isBackCameraLabel(label: string): boolean {
    const lowercaseLabel: string = label.toLowerCase();

    return backCameraKeywords.some((keyword) => {
      return lowercaseLabel.includes(keyword);
    });
  }

  /**
   * @hidden
   *
   * Map non-standard error names to standard ones.
   *
   * @param error The error object.
   */
  function mapNonStandardErrorName(error: Error): void {
    let name: string = error.name;
    // istanbul ignore next
    switch (name) {
      case "DeviceCaptureError":
      case "ScreenCaptureError":
      case "TabCaptureError":
        name = "AbortError";
        break;
      case "InvalidStateError":
      case "MediaDeviceFailedDueToShutdown":
      case "MediaDeviceKillSwitchOn":
      case "PermissionDeniedError":
      case "PermissionDismissedError":
        name = "NotAllowedError";
        break;
      case "DevicesNotFoundError":
        name = "NotFoundError";
        break;
      case "SourceUnavailableError":
      case "TrackStartError":
        name = "NotReadableError";
        break;
      case "ConstraintNotSatisfiedError":
        name = "OverconstrainedError";
        break;
      default:
        return;
    }
    Object.defineProperty(error, "name", {
      value: name,
    });
  }

  /**
   * @hidden
   *
   * @param cameras The array of available [[Camera]] objects.
   * @param activeCamera The current active [[Camera]] object.
   * @param activeCameraIsBackFacing Whether *activeCamera* is facing back (environment).
   */
  function adjustCameraTypes(cameras: Camera[], activeCamera: Camera, activeCameraIsBackFacing: boolean): void {
    // TODO: improve logic for possible multiple front/back cameras
    if (activeCameraIsBackFacing) {
      // Correct camera types if needed
      cameras.forEach((camera) => {
        if (camera.deviceId === activeCamera.deviceId) {
          (<Mutable<Camera>>camera).cameraType = Camera.Type.BACK;
        } else if (!isBackCameraLabel(camera.label)) {
          (<Mutable<Camera>>camera).cameraType = Camera.Type.FRONT;
        }
      });
    } else {
      (<Mutable<Camera>>activeCamera).cameraType = Camera.Type.FRONT;
    }
  }

  /**
   * @hidden
   *
   * Adjusts the cameras' type classification based on the given currently active video stream:
   * If the stream comes from an environment-facing camera, the camera is marked to be a back-facing camera
   * and the other cameras to be of other types accordingly (if they are not correctly set already).
   *
   * The method returns the currently active camera if it's actually the correct (main with wanted type or only) one.
   *
   * @param mediaStreamTrack The currently active `MediaStreamTrack`.
   * @param cameras The array of available [[Camera]] objects.
   * @param wantedCameraType The wanted camera type.
   * @returns The active [[Camera]] object if the stream is actually from the correct camera.
   */
  export function adjustCamerasFromCameraStream(
    mediaStreamTrack: MediaStreamTrack,
    cameras: Camera[],
    wantedCameraType: Camera.Type
  ): Camera | undefined {
    let mediaTrackSettings: MediaTrackSettings | undefined;
    if (typeof mediaStreamTrack.getSettings === "function") {
      mediaTrackSettings = mediaStreamTrack.getSettings();
    }
    const activeCamera: Camera | undefined = cameras.find((camera) => {
      return (
        camera.deviceId === mediaTrackSettings?.deviceId ||
        (camera.label !== "" && camera.label === mediaStreamTrack.label)
      );
    });
    if (activeCamera != null) {
      let mainCameraForType: Camera | undefined;
      if (
        cameras.every((camera) => {
          return camera.label === "";
        })
      ) {
        // When no camera label is available cameras are already in front to back order, assume main front camera is the
        // first one and main back camera is the last one, also don't adjust camera types
        mainCameraForType = cameras[wantedCameraType === Camera.Type.FRONT ? 0 : cameras.length - 1];
      } else {
        adjustCameraTypes(
          cameras,
          activeCamera,
          mediaTrackSettings?.facingMode === "environment" || isBackCameraLabel(mediaStreamTrack.label)
        );

        mainCameraForType = cameras
          .filter((camera) => {
            return camera.cameraType === wantedCameraType;
          })
          .sort((camera1, camera2) => {
            return camera1.label.localeCompare(camera2.label);
          })[0];
      }

      if (cameras.length === 1 || activeCamera.deviceId === mainCameraForType?.deviceId) {
        return activeCamera;
      }
    }

    return undefined;
  }

  /**
   * @hidden
   *
   * @param devices The list of available devices.
   * @returns The extracted list of accessible camera objects initialized from the given devices.
   */
  function extractAccessibleCamerasFromDevices(devices: MediaDeviceInfo[]): Camera[] {
    function createCamera(videoDevice: MediaDeviceInfo, index: number, videoDevices: MediaDeviceInfo[]): Camera {
      if (cameraObjects.has(videoDevice.deviceId)) {
        return <Camera>cameraObjects.get(videoDevice.deviceId);
      }

      const label: string = videoDevice.label ?? "";
      let cameraType: Camera.Type;
      if (
        videoDevices.every((device) => {
          return device.label === "";
        })
      ) {
        // When no camera label is available, assume the camera is a front one if it's the only one or comes in the
        // first half of the list of cameras (if odd number of cameras, more likely to have more back than front ones)
        cameraType =
          videoDevices.length === 1 || index + 1 <= videoDevices.length / 2 ? Camera.Type.FRONT : Camera.Type.BACK;
      } else {
        cameraType = isBackCameraLabel(label) ? Camera.Type.BACK : Camera.Type.FRONT;
      }
      const camera: Camera = {
        deviceId: videoDevice.deviceId,
        label,
        cameraType,
      };

      cameraObjects.set(videoDevice.deviceId, camera);

      return camera;
    }

    const cameras: Camera[] = devices
      .filter((device) => {
        return device.kind === "videoinput";
      })
      .filter((videoDevice) => {
        // Ignore infrared cameras as they often fail to be accessed and are not useful in any case
        return !/\b(?:ir|infrared)\b/i.test(videoDevice.label);
      })
      .filter((videoDevice) => {
        return !inaccessibleCameras.has(videoDevice.deviceId);
      })
      .map(createCamera);
    if (
      cameras.length > 1 &&
      !cameras.some((camera) => {
        return camera.cameraType === Camera.Type.BACK;
      })
    ) {
      // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
      // Otherwise pick the last camera
      let backCameraIndex: number = cameras.length - 1;

      const cameraResolutions: number[] = cameras.map((camera) => {
        const match: RegExpMatchArray | null = camera.label.match(/\b([0-9]+)MP?\b/i);
        if (match != null) {
          return parseInt(match[1], 10);
        }

        return NaN;
      });
      if (
        !cameraResolutions.some((cameraResolution) => {
          return isNaN(cameraResolution);
        })
      ) {
        backCameraIndex = cameraResolutions.lastIndexOf(Math.max(...cameraResolutions));
      }

      (<Mutable<Camera>>cameras[backCameraIndex]).cameraType = Camera.Type.BACK;
    }

    return cameras;
  }

  /**
   * Get a list of cameras (if any) available on the device, a camera access permission is requested to the user
   * the first time this method is called if needed.
   *
   * If the browser is incompatible the returned promise is rejected with a `UnsupportedBrowserError` error.
   *
   * @returns A promise resolving to the array of available [[Camera]] objects (could be empty).
   */
  export function getCameras(): Promise<Camera[]> {
    if (getCamerasPromise != null) {
      return getCamerasPromise;
    }

    const browserCompatibility: BrowserCompatibility = BrowserHelper.checkBrowserCompatibility();
    if (!browserCompatibility.fullSupport) {
      return Promise.reject(new UnsupportedBrowserError(browserCompatibility));
    }

    const accessPermissionPromise: Promise<null | MediaStream> = new Promise((resolve, reject) => {
      return enumerateDevices()
        .then((devices) => {
          if (
            devices
              .filter((device) => {
                return device.kind === "videoinput";
              })
              .every((device) => {
                return device.label === "";
              })
          ) {
            resolve(
              navigator.mediaDevices
                .getUserMedia({
                  video: true,
                  audio: false,
                })
                .catch(
                  /* istanbul ignore next */ () => {
                    // Ignored
                    return null;
                  }
                )
            );
          } else {
            resolve(null);
          }
        })
        .catch(reject);
    });

    getCamerasPromise = new Promise(async (resolve, reject) => {
      let stream!: MediaStream | null;
      try {
        stream = await accessPermissionPromise;
        const devices: MediaDeviceInfo[] = await enumerateDevices();
        const cameras: Camera[] = extractAccessibleCamerasFromDevices(devices);

        console.debug("Camera list: ", ...cameras);

        return resolve(cameras);
      } catch (error) {
        mapNonStandardErrorName(error);
        reject(error);
      } finally {
        stream?.getVideoTracks().forEach((track) => {
          track.stop();
        });
        getCamerasPromise = undefined;
      }
    });

    return getCamerasPromise;
  }

  /**
   * @hidden
   *
   * Call `navigator.mediaDevices.getUserMedia` asynchronously in a `setTimeout` call.
   *
   * @param getUserMediaParams The parameters for the `navigator.mediaDevices.getUserMedia` call.
   * @returns A promise resolving when the camera is accessed.
   */
  function getUserMediaDelayed(getUserMediaParams: MediaStreamConstraints): Promise<MediaStream> {
    console.debug("Camera access:", getUserMediaParams.video);

    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        navigator.mediaDevices.getUserMedia(getUserMediaParams).then(resolve).catch(reject);
      }, 0);
    });
  }

  /**
   * @hidden
   *
   * Get the *getUserMedia* *video* parameters to be used given a resolution fallback level and the browser used.
   *
   * @param resolutionFallbackLevel The number representing the wanted resolution, from 0 to 4,
   * resulting in higher to lower video resolutions.
   * @returns The resulting *getUserMedia* *video* parameters.
   */
  function getUserMediaVideoParams(resolutionFallbackLevel: number): MediaTrackConstraints {
    const userMediaVideoParams: MediaTrackConstraints = {
      resizeMode: "none",
    };
    switch (resolutionFallbackLevel) {
      case 0:
        return {
          ...userMediaVideoParams,
          width: { min: 3200, ideal: 3840, max: 4096 },
          height: { min: 1800, ideal: 2160, max: 2400 },
        };
      case 1:
        return {
          ...userMediaVideoParams,
          width: { min: 1400, ideal: 1920, max: 2160 },
          height: { min: 900, ideal: 1080, max: 1440 },
        };
      case 2:
        return {
          ...userMediaVideoParams,
          width: { min: 960, ideal: 1280, max: 1440 },
          height: { min: 480, ideal: 720, max: 960 },
        };
      case 3:
        return {
          ...userMediaVideoParams,
          width: { min: 640, ideal: 640, max: 800 },
          height: { min: 480, ideal: 480, max: 600 },
        };
      default:
        return {};
    }
  }

  /**
   * @hidden
   *
   * Try to access a given camera for video input at the given resolution level.
   *
   * If a camera is inaccessible because of unknown issues, then it's added to the device blacklist.
   *
   * @param resolutionFallbackLevel The number representing the wanted resolution, from 0 to 4,
   * resulting in higher to lower video resolutions.
   * @param camera The camera to try to access for video input.
   * @returns A promise resolving to the `MediaStream` object coming from the accessed camera.
   */
  export function accessCameraStream(resolutionFallbackLevel: number, camera: Camera): Promise<MediaStream> {
    const getUserMediaParams: MediaStreamConstraints = {
      audio: false,
      video: getUserMediaVideoParams(resolutionFallbackLevel),
    };

    if (camera.deviceId === "") {
      (<MediaTrackConstraints>getUserMediaParams.video).facingMode = {
        ideal: camera.cameraType === Camera.Type.BACK ? "environment" : "user",
      };
    } else {
      (<MediaTrackConstraints>getUserMediaParams.video).deviceId = {
        exact: camera.deviceId,
      };
    }

    return getUserMediaDelayed(getUserMediaParams).catch((error) => {
      mapNonStandardErrorName(error);
      if (camera.deviceId !== "" && (error.name !== "OverconstrainedError" || resolutionFallbackLevel === 4)) {
        inaccessibleCameras.add(camera.deviceId);
        cameraObjects.delete(camera.deviceId);
      }
      throw error;
    });
  }

  /**
   * @hidden
   *
   * Get a list of available devices in a cross-browser compatible way.
   *
   * @returns A promise resolving to the `MediaDeviceInfo` array of all available devices.
   */
  function enumerateDevices(): Promise<MediaDeviceInfo[]> {
    if (typeof navigator.enumerateDevices === "function") {
      return navigator.enumerateDevices();
    } else if (
      typeof navigator.mediaDevices === "object" &&
      typeof navigator.mediaDevices.enumerateDevices === "function"
    ) {
      return navigator.mediaDevices.enumerateDevices();
    } else {
      return new Promise((resolve, reject) => {
        try {
          if (window.MediaStreamTrack?.getSources == null) {
            throw new Error();
          }
          window.MediaStreamTrack.getSources((devices) => {
            resolve(
              devices
                .filter((device) => {
                  return device.kind.toLowerCase() === "video" || device.kind.toLowerCase() === "videoinput";
                })
                .map((device) => {
                  return {
                    deviceId: device.deviceId ?? "",
                    groupId: device.groupId,
                    kind: "videoinput",
                    label: device.label,
                    toJSON: /* istanbul ignore next */ function (): MediaDeviceInfo {
                      return this;
                    },
                  };
                })
            );
          });
        } catch {
          const browserCompatibility: BrowserCompatibility = {
            fullSupport: false,
            scannerSupport: true,
            missingFeatures: [BrowserCompatibility.Feature.MEDIA_DEVICES],
          };

          return reject(new UnsupportedBrowserError(browserCompatibility));
        }
      });
    }
  }
}
