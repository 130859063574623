import { Camera } from "../camera";
import { CameraSettings } from "../cameraSettings";
import { CameraManager } from "./cameraManager";

/**
 * A dummy barcode picker utility class used to (not) handle camera interaction.
 */
// istanbul ignore next
export class DummyCameraManager extends CameraManager {
  public setInteractionOptions(_1: boolean, _2: boolean, _3: boolean, _4: boolean): void {
    return;
  }

  public isCameraSwitcherEnabled(): boolean {
    return false;
  }

  public setCameraSwitcherEnabled(_1: boolean): Promise<void> {
    return Promise.resolve();
  }

  public isTorchToggleEnabled(): boolean {
    return false;
  }

  public setTorchToggleEnabled(_1: boolean): void {
    return;
  }

  public isTapToFocusEnabled(): boolean {
    return false;
  }

  public setTapToFocusEnabled(_1: boolean): void {
    return;
  }

  public isPinchToZoomEnabled(): boolean {
    return false;
  }

  public setPinchToZoomEnabled(_1: boolean): void {
    return;
  }

  public setInitialCameraType(_1?: Camera.Type): void {
    return;
  }

  public setSelectedCamera(_1?: Camera): void {
    return;
  }

  public setSelectedCameraSettings(_1?: CameraSettings): void {
    return;
  }

  public setupCameras(): Promise<void> {
    return Promise.resolve();
  }

  public stopStream(): void {
    return;
  }

  public applyCameraSettings(_1?: CameraSettings): Promise<void> {
    return Promise.resolve();
  }

  public reinitializeCamera(): Promise<void> {
    return Promise.resolve();
  }

  public initializeCameraWithSettings(_1: Camera, _2?: CameraSettings): Promise<void> {
    return Promise.resolve();
  }

  public setTorchEnabled(_1: boolean): Promise<void> {
    return Promise.resolve();
  }

  public toggleTorch(): Promise<void> {
    return Promise.resolve();
  }

  public setZoom(_1: number, _2?: number): Promise<void> {
    return Promise.resolve();
  }
}
