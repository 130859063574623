import Cookies from "js-cookie";
import { UAParser } from "ua-parser-js";
export { UAParser };

import { BrowserCompatibility } from "./browserCompatibility";

export namespace BrowserHelper {
  /**
   * @hidden
   */
  interface Browser {
    name?: string;
    version?: string;
  }

  /**
   * @hidden
   */
  interface CPU {
    architecture?: string;
  }

  /**
   * @hidden
   */
  interface Device {
    model?: string;
    vendor?: string;
    // tslint:disable-next-line:no-reserved-keywords
    type?: string;
  }

  /**
   * @hidden
   */
  interface Engine {
    name?: string;
    version?: string;
  }

  /**
   * @hidden
   */
  interface OS {
    name?: string;
    version?: string;
  }

  /**
   * @hidden
   */
  export const userAgentInfo: {
    getBrowser(): Browser;
    getOS(): OS;
    getEngine(): Engine;
    getDevice(): Device;
    getCPU(): CPU;
    getUA(): string;
    setUA(uastring: string): void;
  } = new UAParser(navigator.userAgent);

  /**
   * @hidden
   */
  export const canvas: HTMLCanvasElement = document.createElement("canvas");

  /**
   * @returns The built [[BrowserCompatibility]] object representing the current OS/Browser's support for features.
   */
  export function checkBrowserCompatibility(): BrowserCompatibility {
    function objectHasPropertyWithType(object: object, propertyNames: string[], propertyType: string): boolean {
      // tslint:disable-next-line:no-any
      const objectProperty: any = (<any>object)[propertyNames[0]];
      if (objectProperty == null) {
        return false;
      }
      if (propertyNames.length === 1) {
        return typeof objectProperty === propertyType;
      } else {
        return (
          (typeof objectProperty === "function" || typeof objectProperty === "object") &&
          objectHasPropertyWithType(objectProperty, propertyNames.slice(1), propertyType)
        );
      }
    }

    function isBrokenWebAssemblyOS(os: OS): boolean {
      return os.name === "iOS" && os.version != null && ["11.2.2", "11.2.5", "11.2.6"].includes(os.version);
    }

    let fullSupport: boolean = true;
    let scannerSupport: boolean = true;
    const missingFeatures: BrowserCompatibility.Feature[] = [];

    if (
      !objectHasPropertyWithType(navigator, ["mediaDevices", "getUserMedia"], "function") &&
      !objectHasPropertyWithType(navigator, ["enumerateDevices"], "function") &&
      !objectHasPropertyWithType(window, ["MediaStreamTrack", "getSources"], "function")
    ) {
      missingFeatures.push(BrowserCompatibility.Feature.MEDIA_DEVICES);
      fullSupport = false;
    }

    if (!objectHasPropertyWithType(window, ["Worker"], "function")) {
      missingFeatures.push(BrowserCompatibility.Feature.WEB_WORKERS);
      fullSupport = scannerSupport = false;
    }

    if (!objectHasPropertyWithType(window, ["WebAssembly"], "object")) {
      missingFeatures.push(BrowserCompatibility.Feature.WEB_ASSEMBLY);
      fullSupport = scannerSupport = false;
    }

    if (!objectHasPropertyWithType(window, ["Blob"], "function")) {
      missingFeatures.push(BrowserCompatibility.Feature.BLOB);
      fullSupport = scannerSupport = false;
    }

    if (!objectHasPropertyWithType(window, ["URL", "createObjectURL"], "function")) {
      missingFeatures.push(BrowserCompatibility.Feature.URL_OBJECT);
      fullSupport = scannerSupport = false;
    }

    if (!objectHasPropertyWithType(window, ["OffscreenCanvas"], "function")) {
      missingFeatures.push(BrowserCompatibility.Feature.OFFSCREEN_CANVAS);
    }

    try {
      if (
        !objectHasPropertyWithType(window, ["WebGLRenderingContext"], "function") ||
        (canvas.getContext("webgl") == null && canvas.getContext("experimental-webgl") == null)
      ) {
        throw new Error();
      }
    } catch {
      missingFeatures.push(BrowserCompatibility.Feature.WEBGL);
    }

    const userAgentOS: OS = userAgentInfo.getOS();
    if (isBrokenWebAssemblyOS(userAgentOS)) {
      missingFeatures.push(BrowserCompatibility.Feature.WEB_ASSEMBLY_ERROR_FREE);
      fullSupport = scannerSupport = false;
    }

    return {
      fullSupport,
      scannerSupport,
      missingFeatures,
    };
  }

  /**
   * @hidden
   *
   * Get a device id for the current browser.
   *
   * When available it's retrieved from localStorage, as fallback from cookies (used by older library versions),
   * when not available it's randomly generated and stored in localStorage to be retrieved by later calls and returned.
   *
   * @returns The device id for the current browser.
   */
  export function getDeviceId(): string {
    const devideIdKey: string = "scandit-device-id";
    let deviceId: string | undefined | null = localStorage.getItem(devideIdKey);
    if (deviceId != null && deviceId !== "") {
      return deviceId;
    }

    deviceId = Cookies.get(devideIdKey);
    if (deviceId != null && deviceId !== "") {
      localStorage.setItem(devideIdKey, deviceId);

      return deviceId;
    }

    const randomDeviceIdBytes: Uint8Array = new Uint8Array(20);
    crypto.getRandomValues(randomDeviceIdBytes);
    deviceId = Array.from(randomDeviceIdBytes)
      .map((byteNumber) => {
        const byteHex: string = byteNumber.toString(16);

        return byteHex.length === 1 ? /* istanbul ignore next */ `0${byteHex}` : byteHex;
      })
      .join("");

    localStorage.setItem(devideIdKey, deviceId);

    return deviceId;
  }

  /**
   * @hidden
   *
   * Check if a given object is a valid HTMLElement
   *
   * @param object The object to check.
   * @returns Whether the given object is a valid HTMLElement.
   */
  // tslint:disable-next-line:no-any
  export function isValidHTMLElement(object: any): boolean {
    return (
      object instanceof HTMLElement ||
      (object != null && typeof object === "object" && typeof object.tagName === "string")
    );
  }
}
